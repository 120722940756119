
html,body,*{
    font-family: Poppins-Regular;
}

body {
    margin: 0;
    /* font-family: Poppins-Regular; */
    font-size: 16px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #FFFFFF;
}

main {
    flex-grow: 1;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.button, button, input, select {
    font: inherit;
}

.button, button, select, input[type="color"], input[type="checkbox"] {
    cursor: pointer;
}

.button {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: background-color 0.2s ease-out;
    background-color: #4DAA50;
    border: none;
    color: white;
    text-decoration: inherit;
}

button {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: background-color 0.2s ease-out;
    background-color: #4DAA50;
    border: none;
    color: inherit;
    text-decoration: inherit;
}


input[type="color"] {
    transition: background-color 0.2s ease-out;
    background-color:#A6A6A6;
    border: none;
    padding: 5px;
    height: 30px;
}

input[type=file]::file-selector-button {
    border: 1px solid #A6A6A6;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 25px;
    background-color: #ffffff;
    color: #4DAA50;
    font-family: Poppins-Regular;
    /* margin-right: 15px; */
  }
  
  input[type=file]::file-selector-button:hover {
    background-color: #EEEEEE;
    border: 1px solid #A6A6A6;
  }

select {
    /* padding: 9px; */
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: background-color 0.2s ease-out;
    background-color: rgb(166,166,166,0.5);
    border: none;
}

select > option {
    background-color: #EEEEEE;
}

.button:hover {
    background-color: #028A0F;
}

button:hover {
    background-color: #028A0F;
}
button.button-Branding[disabled]:hover {
    background-color: #4DAA50;
    cursor: not-allowed;
}

select:hover {
    background-color: #A6A6A6;
}

input[type="color"]:hover {
    background-color: rgb(166,166,166,0.5);
}

a.white, a.black {
    transition: color 0.4s;
}

a.white:link {
    color: #f5f5f5;
    text-decoration: none;
}

a.white:visited {
    color: #f5f5f5;
}

a.white:hover {
    color: #cdcdcd;
}

a.white:active {
    color: #9b9b9b;
}

a.black:link {
    color: #000000;
    text-decoration: none;
}

a.black:visited {
    color: #000000;
}

a.black:hover {
    color: #7a7a7a;
}

a.black:active {
    color: #afafaf;
}

input[type="text"], input[type="number"] {
    border-width: 1px;
    border-style: solid;
    border-color: #A6A6A640;
    padding: 5px;
}

.accordion {
    background-color: #A6A6A680;
    padding: 10px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: background-color 0.4s;
}

.accordion:after {
    content: '\002B';
    font-weight: bold;
    float: right;
    margin-left: 5px;
}

.accordion.active:after {
    content: "\2212";
}

.accordion.accordion--open {
    cursor: auto;
}

.accordion.accordion--open:after {
    content: none;
}

.accordion:hover {
    background-color: #A6A6A640;
}

.panel {
    padding: 20px;
    background-color: #EEEEEE;
    display: none;
    overflow: hidden;
    grid-template-columns: auto 1fr;
    grid-gap: 15px;
    align-items: center;
}

.panel.panel--open {
    display: grid;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container > * {
    width: 100%;
    max-width: 1008px;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.row--qr-header {
    align-items: center;
}

.row--body {
    align-items: start;
}

.col {
    padding: 20px;
}

.buttons-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.buttons-container button {
    margin-left: 10px;
}

.buttons-container button:first-of-type {
    margin-left: auto;
}

.space-between-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.space-between-container input:disabled + label {
    color: #999; 
}

.buttons-container button {
    margin-left: 10px;
}

.buttons-container button:first-of-type {
    margin-left: auto;
}

#form-image-file {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
}

.qr-logo {
    flex-grow: 1;
    padding-top: 5px;
    padding-bottom: 5px;
}

.qr-logo__img {
    height: 50px;
}

.qr-description {
    color: #fff;
    min-height: 30vh;
    justify-content: center;
    font-size: 21px;
}

.qr-description__lib-name {
    font-size: 48px;
}

.qr-form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.qr-header {
    background-color: #004877;
    color: #ffffff;
}

.qr-footer {
    background-color: #004877;
    color: #ffffff;
}

.qr-code canvas {
    max-width: 300px!important;
    width: 100%;
}

.qr-download-group {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

/* .qr-download-group select {
    border-left: 1px solid  #fff;
} */

.qr-code-container {
    max-width: 100%;
    position: sticky;
    top: 5px;
}

.options-export-group {
    margin-top: 20px;
}


#form-data {
    width: 100%;
}

.hide {
    /*visibility: hidden;*/
    height: 0;
    width: 0;
    visibility: hidden;
}
input:disabled{
    color:#A6A6A680;
    cursor: not-allowed;
}
